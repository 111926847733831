import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 10px 0 0 0;
    li{
        font-weight: 400;
        width: 100%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Bigsub = styled.h2`
    font-weight: 700;
    font-size: 28px;
    color: var(--green-text);
`

const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "diensticonen/recycling.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        anotherImage: file(relativePath: { eq: "recycling/recycling.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
    return image[something];
    }


const MedewerkerBinnendienst = () => (
    
  <Layout>
    <SEO title="Vactures" />
    <PageHeader title="Vacatures">
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink href="/informatie" onClick={handleClick}>
            Informatie
          </Crumblink>
          <Crumblink href="/informatie/vacatures" onClick={handleClick}>
            Vacatures
          </Crumblink>
          <Crumblink aria-current="page" href="/informatie/vacatures/Medewerker Binnendienst" onClick={handleClick}>
            Medewerker Binnendienst
          </Crumblink>
        </Styledcrumbs>
    </PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    <Paragraph>
                    <Bigsub>Vacature Medewerker Binnendienst</Bigsub>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Subheader>Functieomschrijving</Subheader>
                    <Paragraph>

                             Green Digital is een organisatie gericht op duurzaamheid en maatschappelijk verantwoord ondernemen. Wij zijn gespecialiseerd in de inzameling, inkoop, reparatie en verkoop van smartphones en ICT apparatuur.
                    
                    </Paragraph>
                    <Paragraph>
            
                            Wij zijn per direct op zoek naar een medewerker binnendienst (32-40 uur).
            
                    </Paragraph>
                    <Paragraph>

                            Sta jij als starter aan het begin van je loonbaan of heb jij al wel wat relevante werkervaring opgedaan en ben je toe aan een nieuwe uitdaging ? Dan kun je je bij ons in een dynamische omgeving ontwikkelen.

                    </Paragraph>
                    <Paragraph>

                            Als medewerker binnendienst ga je met name uitgaande telefonische gesprekken aan met nieuwe en bestaande relaties. In deze functie ben je het visitekaartje van het bedrijf. Het is jouw taak om de contacten en processen zo goed mogelijk af te handelen. Het is belangrijk dat jij je verantwoordelijk voelt voor het juiste verloop van de binnendienst-processen en de tevredenheid van onze relaties.

                    </Paragraph>
                    <Paragraph>

                            Wij zoeken een toegewijde kandidaat die graag en gemakkelijk communiceert en correspondeert. Je hebt kennis van administratieve processen en van nature ben je een gedreven en resultaatgericht persoon.

                    </Paragraph>
                    <Paragraph>

                            Je komt te werken in een gezellig en informeel team waar veel ruimte is voor verdere ontplooiing.

                    </Paragraph>
                    <Paragraph>

                            Onze organisatie, visie en missie is gericht op duurzaamheid en maatschappelijk verantwoord ondernemen en de kandidaat die wij zoeken kan en wil zich nadrukkelijk verbinden met ons "groene" hart.

                    </Paragraph>        
                    <Paragraph>
                    <Paragraph />
                    <Paragraph />
                    <b>De kennis en vaardigheden die wij daarbij waarderen:</b><br/>
                      <Itemlist>
                                  <li><StyledLiIcon icon={faDotCircle} /> Ervaring in klantencontacten bij voorkeur in een vergelijkbare functie.<br/></li>
                                  <li><StyledLiIcon icon={faDotCircle} /> Beheersing van de Nederlandse taal in communicatie en correspondentie.<br/></li>
                      </Itemlist>
                    </Paragraph>
                    <Paragraph>
                    <Paragraph />
                    <Paragraph />
                    <b>Eigenschappen welke wij op prijs stellen:</b><br/>
                      <Itemlist>
                                  <li><StyledLiIcon icon={faDotCircle} /> Je bent gezellig en legt gemakkelijk contacten.<br/></li>
                                  <li><StyledLiIcon icon={faDotCircle} /> Je bent een enthousiast, vrolijk persoon en een teamspeler.<br/></li>
                                  <li><StyledLiIcon icon={faDotCircle} /> Je hebt verantwoordelijkheidsgevoel en bent oplossingsgericht.</li>
                      </Itemlist>
                      <br /><br /><br/>
                      <b>Herken je jezelf in dit profiel en ben je toe aan een nieuwe uitdaging ? Dan kun je bij ons in een dynamische omgeving meegroeien en jezelf ontwikkelen.</b>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Paragraph>
                    <b>Wij bieden:</b><br/>
                      <Itemlist>
                                  <li><StyledLiIcon icon={faDotCircle} /> Werken bij een "groen" en groeiend bedrijf met volop ontwikkelingsmogelijkheden.</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Fijne collega's en een gezellige en informele werksfeer.</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Flexibele werkuren.</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Een salaris afhankelijk van opleiding en ervaring.</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Laptop.</li>
                      </Itemlist>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Paragraph>
                        <b>Secundaire arbeidsvoorwaarden:</b>
                        <Itemlist>
                                    <li><StyledLiIcon icon={faDotCircle} /> Flexibele werkuren</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Vakantiegeld</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Bedrijfstelefoon</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Reiskostenvergoeding</li>
                        </Itemlist>
                     
                    </Paragraph>
                    <Paragraph/>
                    <Paragraph/>
                    <Paragraph>
                        
                        Contractduur 6-12 maanden met uitzicht op vast dienstverband.<br/><br/>

                        Soort dienstverband: Fulltime, Stage, Bepaalde tijd.<br/><br/>

                        Salaris: €1.700,00 - €2.200,00 per maand

                    </Paragraph>
                
                </Vertpad>
                
            </Containerpad>
        </Maincontent>
    <Padding/>
  </Layout> 
)

export default MedewerkerBinnendienst


